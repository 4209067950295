// 前端测试多选框功能
<template>
    <div>
    <el-select v-model="value1" multiple placeholder="请选择">
        <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
    </el-select>
    <el-button @click="getInfo">提交</el-button>
    </div>
</template>

<script>
    export default {
        name: "testTk",
        data() {
            return {
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                value1: [],
                value2: []
            }
        },
        methods:{
            getInfo(){
                console.log(this.value1) ;
            }
        }
    }
</script>

<style scoped>

</style>